.weatherforecastItemImg {
  height: 100px;
  width: 100px;
}

.futureForecastTtodayTemp {
  font-size: 16px;
  padding-top: 15px;
}

.futureForecastTodayDay {
  padding: 5px 15px;
  background: #89726b;
  text-align: center;
  border-radius: 0;
  margin-bottom: 10px;
}

.others {
  padding: 12px;
}

.boxInfovoli {
  margin-left: 10px;
  color: white;
}

.boxTreni {
  margin-left: 10px;
}

.boxLavoro {
  margin-left: 10px;
  color: white;
}

.boxContatti {
  margin-left: 10px;
  color: white;
}

.boxPromozioni {
  margin-left: 10px;
  color: white;
}

.boxEventiNews {
  margin-left: 10px;
  color: white;
}

.boxMeteo {
  margin-left: 10px;
  color: white;
}

.boxPiantaNegozi {
  margin-left: 60px;
}
.boxPiantaNegozi {
  margin-left: 60px;
}

.boxImagePianta {
  margin-top: -0px;
  padding: 20px;
}
