@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;700&display=swap");


/* add 25/01/2023 */
@font-face {
  font-family: 'scoutbold';
  src: url('https://centroleonardopwa.online/assets/fonts/scout-bold-webfont.eot');
  src: url('https://centroleonardopwa.online/assets/fonts/scout-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-bold-webfont.woff2') format('woff2'),
       url('https://centroleonardopwa.online/assets/fonts/scout-bold-webfont.woff') format('woff'),
       url('https://centroleonardopwa.online/assets/fonts/scout-bold-webfont.ttf') format('truetype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-bold-webfont.svg#scoutbold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'scoutextralight';
  src: url('https://centroleonardopwa.online/assets/fonts/scout-extralight-webfont.eot');
  src: url('https://centroleonardopwa.online/assets/fonts/scout-extralight-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-extralight-webfont.woff2') format('woff2'),
       url('https://centroleonardopwa.online/assets/fonts/scout-extralight-webfont.woff') format('woff'),
       url('https://centroleonardopwa.online/assets/fonts/scout-extralight-webfont.ttf') format('truetype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-extralight-webfont.svg#scoutextralight') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'scoutlight';
  src: url('https://centroleonardopwa.online/assets/fonts/scout-light-webfont.eot');
  src: url('https://centroleonardopwa.online/assets/fonts/scout-light-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-light-webfont.woff2') format('woff2'),
       url('https://centroleonardopwa.online/assets/fonts/scout-light-webfont.woff') format('woff'),
       url('https://centroleonardopwa.online/assets/fonts/scout-light-webfont.ttf') format('truetype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-light-webfont.svg#scoutlight') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'scoutregular';
  src: url('https://centroleonardopwa.online/assets/fonts/scout-regular-webfont.eot');
  src: url('https://centroleonardopwa.online/assets/fonts/scout-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-regular-webfont.woff2') format('woff2'),
       url('https://centroleonardopwa.online/assets/fonts/scout-regular-webfont.woff') format('woff'),
       url('https://centroleonardopwa.online/assets/fonts/scout-regular-webfont.ttf') format('truetype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-regular-webfont.svg#scoutregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'scoutthin';
  src: url('https://centroleonardopwa.online/assets/fonts/scout-thin-webfont.eot');
  src: url('https://centroleonardopwa.online/assets/fonts/scout-thin-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-thin-webfont.woff2') format('woff2'),
       url('https://centroleonardopwa.online/assets/fonts/scout-thin-webfont.woff') format('woff'),
       url('https://centroleonardopwa.online/assets/fonts/scout-thin-webfont.ttf') format('truetype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-thin-webfont.svg#scoutthin') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'scoutblack';
  src: url('https://centroleonardopwa.online/assets/fonts/scout-black-webfont.eot');
  src: url('https://centroleonardopwa.online/assets/fonts/scout-black-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-black-webfont.woff2') format('woff2'),
       url('https://centroleonardopwa.online/assets/fonts/scout-black-webfont.woff') format('woff'),
       url('https://centroleonardopwa.online/assets/fonts/scout-black-webfont.ttf') format('truetype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-black-webfont.svg#scoutblack') format('svg');
  font-weight: normal;
  font-style: normal;
}

/**/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  /* background: url("https://farm6.staticflickr.com/5613/14961821903_670edd3ac5_o.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  /*overflow: hidden;*/
  /*height: 100vh;*/
  
  font-family: "Poppins", sans-serif;
}

img {
  width: auto;
  margin: 0 auto;
  display: block;
  float: none;
  max-width: 100%;
}

/* METEO */

.rotate-sereno {
  transform: rotate(0deg);
  width: 1080px;
  height: 1920px;
  position: absolute;
 /* top: -420px;*/
 /* left: 420px;*/
  background: url("https://centroleonardopwa.online/bkg/sereno.jpg");
  background-repeat: no-repeat;
  background-size: cover;
} 

.future-forecast .today {
  margin-right: 10px;
  padding-bottom: 26px;
  padding-top: 26px;
}
.future-forecast .today {
  background: #b7a4a1;
  border-radius: 0;
  margin-right: 40px;
  padding-right: 15px;
}
.future-forecast .today {
  align-items: center;
  background: rgba(0,0,0,.2);
  border: 1px solid #eee;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: 15px 40px 15px 15px;
}

.weather_futureForecastTodayDay {
  background: #89726b;
  border-radius: 0;
  margin-bottom: 10px;
}
/*
.rotate-sereno {
  transform: rotate(0deg);
  width: 1080px;
  height: 1920px;
  position: absolute;
  left: 420px;
  background: url("https://www.secure-worldevent.com/totemleo/bkg/sereno.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}*/
/*
.rotate-nuvoloso {
  transform: rotate(-90deg);
  width: 1080px;
  height: 1920px;
  position: absolute;
  top: -420px;
  left: 420px;
  background: url("https://www.secure-worldevent.com/totemleo/bkg/nuvoloso.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.rotate-nubisparse {
  transform: rotate(-90deg);
  width: 1080px;
  height: 1920px;
  position: absolute;
  top: -420px;
  left: 420px;
  background: url("https://www.secure-worldevent.com/totemleo/bkg/parzialmente nuvoloso.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.rotate-parzialmentenuvoloso {
  transform: rotate(-90deg);
  width: 1080px;
  height: 1920px;
  position: absolute;
  top: -420px;
  left: 420px;
  background: url("https://www.secure-worldevent.com/totemleo/bkg/parzialmente nuvoloso.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.rotate-pioggialeggera {
  transform: rotate(-90deg);
  width: 1080px;
  height: 1920px;
  position: absolute;
  top: -420px;
  left: 420px;
  background: url("https://www.secure-worldevent.com/totemleo/bkg/pioggia leggera.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.rotate-pioggia {
  transform: rotate(-90deg);
  width: 1080px;
  height: 1920px;
  position: absolute;
  top: -420px;
  left: 420px;
  background: url("https://www.secure-worldevent.com/totemleo/bkg/pioggia.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.rotate-temporale {
  transform: rotate(-90deg);
  width: 1080px;
  height: 1920px;
  position: absolute;
  top: -420px;
  left: 420px;
  background: url("https://www.secure-worldevent.com/totemleo/bkg/temporale.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.rotate-neve {
  transform: rotate(-90deg);
  width: 1080px;
  height: 1920px;
  position: absolute;
  top: -420px;
  left: 420px;
  background: url("https://www.secure-worldevent.com/totemleo/bkg/neve.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.rotate-nebbia {
  transform: rotate(-90deg);
  width: 1080px;
  height: 1920px;
  position: absolute;
  top: -420px;
  left: 420px;
  background: url("https://www.secure-worldevent.com/totemleo/bkg/nebbia.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
/*END METEO */

.container {
  padding: 20px 70px;
  color: #fff;
}

.current-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.date-container {
  font-weight: 100;
}
.date-container .time {
  font-size: 70px;
}

.date-container #am-pm {
  font-size: 30px;
  margin-left: 20px;
}

.date-container .date {
  font-size: 30px;
}

.place-container {
  text-align: end;
}

.place-container .time-zone {
  font-size: 30px;
  font-weight: 100;
}

.place-container .country {
  font-size: 12px;
  font-weight: 700;
}

.current-info .others {
  display: flex;
  flex-direction: column;
  background: rgba(24, 24, 27, 0.6);
  padding: 20px;
  border-radius: 10px;
  margin: 10px 0;
  border: 1px solid #eee;
}

.current-info .others .weather-item {
  display: flex;
  justify-content: space-between;
}

.future-forecast {
  background: rgba(24, 24, 27, 0.8);
  padding: 25px;
  position: fixed;
  top: 0;
  display: flex;
  color: white;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}

.future-news {
  background: rgba(24, 24, 27, 0.8);
  padding: 25px;
  position: fixed;
  bottom: 0;
  display: flex;
  color: white;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.future-forecast .today {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 15px;
  padding-right: 40px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

.future-forecast .today .day {
  padding: 5px 15px;
  background: #3c3c44;
  border-radius: 50px;
  text-align: center;
}

.future-forecast .weather-forecast {
  display: flex;
}

.weather-forecast .weather-forecast-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  border: 1px solid #eee;
  padding: 15px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

.weather-forecast .weather-forecast-item .day {
  padding: 5px 15px;
  background: #3c3c44;
  border-radius: 50px;
  text-align: center;
}

.weather-forecast .weather-forecast-item .temp {
  font-weight: 100;
  font-size: 12px;
}

@media only screen and (max-width: 730px) {
  .container {
    padding: 20px;
  }

  .future-forecast {
    justify-content: start;
    align-items: none;
    overflow-y: scroll;
  }

  .future-forecast .today .temp {
    font-size: 16px;
  }

  .date-container .time {
    font-size: 50px;
  }

  .date-container #am-pm {
    font-size: 20px;
  }

  .date-container .date {
    font-size: 20px;
  }

  .place-container {
    text-align: end;
    margin-top: 15px;
  }

  .place-container .time-zone {
    font-size: 20px;
  }

  .current-info .others {
    padding: 12px;
  }

  .current-info .others .weather-item {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1400px) {
  .future-forecast {
    justify-content: start;
    align-items: none;
    overflow-x: scroll;
  }
}

.dataoralogo {
  width: 210px;
  margin-right: 30px;
  padding-top: 25px;
}

.future-forecast .today {
  margin-right: 10px;
} 

.data_top {
  background: #b7a4a1;
  text-align: center;
}

.ora_top {
  font-size: 30px;
  text-align: center;
}

.data_top {
  background: #b7a4a1;
  text-align: center;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 20px;
}

.dataoralogo {
  width: 210px;
  margin-right: 30px;
  padding-top: 40px;
}

.weather-forecast .weather-forecast-item .day {
  border-radius: 0px;
}

.ticker-wrapper {
  position: relative;
  max-width: 1073px;
  margin: auto;
  /* padding-top: 200px; */
}

.bigHeading {
  display: block;
  position: absolute;
  font-style: italic;
  font-size: 40px;
  background-color: #e60000;
  color: #fff;
  text-transform: uppercase;
  padding: 15px;
  letter-spacing: 3px;
  font-weight: 700;
  z-index: 2;
}

.bigHeading::after .bigHeading::before {
  content: "";
  width: 0;
  position: absolute;
  border-width: 78px;
  border-style: solid;
  top: 0;
  z-index: -1;
}

.bigHeading::before {
  border-color: #e60000 transparent transparent transparent;
  right: -78px;
}

.bigHeading::after {
  border-color: transparent transparent #e60000 transparent;
  left: -78px;
  top: -78px;
}

.text-update {
  height: 70px;
  background: #004fff;
  overflow: hidden;
  position: relative;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 25px;
  font-weight: 500;
  display: flex;
  align-items: center;
  left: 12px;
}

.text-update p {
  position: absolute;
  width: 150%;
  margin: 0;
  font-weight: 700;
  text-align: left;

  transform: translate(100%);
  animation: moving 25s linear infinite;
}

.metro {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 636px;
  transform: translate(-50%, -50%);
}

.item {
  background: black;
  width: 200px;
  height: 250px;
  float: left;
  margin: 6px;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.06s;
  position: relative;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  list-style: none;
  color: whitesmoke;
  display: list-item;
}

.item:hover {
/*  transform: scale(0.9);*/
  cursor: pointer;
  overflow: hidden;
}

.item span {
  font-size: 25px;
  position: absolute;
  bottom: 6px;
  left: 6px;
}

.item i {
  font-size: 100px;
  line-height: 190px;
}

.i1 {
  background: #c2b5b2; /**/
}

.i2 {
  background: #b09e9a; /**/
}

.i3 {
  background: #947c76; /**/
}

.i4 {
  background: #a6938e; /**/
}

.i5 {
  background: #89726b;
  height: 300px!important;
}

.i6 {
  background: #b9aaa6; /* */
}

.i7 {
  background: #9d8882; /**/
}

.i8 {
  background: #89726b;
}

.i9 {
  background: #a6938e; /**/
  width: 412px;
}

/* add 17/11/2023 */

.i11{
  background-color: #045db9;
}


/**/

.popup {
  background-color: #ffffff;
  width: 550px;
  padding: 30px 40px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  display: none;
  text-align: center;
}

.popup button {
  display: block;
  margin: 0 0 20px auto;
  background-color: transparent;
  font-size: 30px;
  color: #c5c5c5;
  border: none;
  outline: none;
  cursor: pointer;
}

.popup p {
  font-size: 14px;
  text-align: justify;
  margin: 20px 0;
  line-height: 25px;
}

input[type="submit"] {
  display: block;
  width: 150px;
  position: relative;
  margin: 10px auto;
  text-align: center;
  background-color: #0f72e5;
  color: #ffffff;
  text-decoration: none;
  padding: 5px 0;
}

/** */
/* polling */
.pollabel:before {
  content: "☆";
  color: black;
  font-size: 2em;
}

.pollabel.on:before {
  content: "★";
  color: yellow;
  /* uncomment for iOS */
  /*   font-size: 2.4em;
      top: -0.1em;
      left: -0.1em; */
}

input:checked + .pollabel:before {
  content: "★";
  color: yellow;
  /* uncomment for iOS */
  /*   font-size: 2.4em;
  top: -0.1em;
  left: -0.1em; */
}

.pollradio {
  display: none;
  -webkit-appearance: none;
}

.pollabel {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 15px;
  margin-right: 15px;
  font-size: 20px;
}

.pollabelbel:before {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  position: absolute;
  left: 0;
  border-radius: 10px;
}

a {
  text-decoration: none !important;
}

#q3:hover {
  cursor: pointer;
}

/** */

input[type="submit"] {
  background-color: #b7a4a1 !important;
}

.item span {
  font-size: 25px;
  line-height: 1.1;
  position: absolute;
  bottom: 12px;
  left: 0;
  width: 100%;
  display: block;
  margin-left: 0px !important;
}

.future-forecast .today {
  background: #b7a4a1;
  border-radius: 0px;
  padding-right: 15px;
  margin-right: 40px;
}

.weather-forecast .weather-forecast-item {
  border-radius: 0px;
}

.future-forecast .today .day {
  border-radius: 0px;
}

.breaking-alert span {
  color: white;
  font-weight: 700;
  letter-spacing: 1.5px;
  padding: 10px;
}
/* NEWS */

.wrapper .headline-wrapper .news-wrapper.in {
  opacity: 1;
}
.wrapper .headline-wrapper .news-wrapper {
  width: 100%;
  height: inherit;
  padding: 15px 30px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.news_wrapper__yKHm8 {
  background-color: #f8f9fa;
  overflow: hidden;
}
.news_wrapper__yKHm8 {
  background-color: #f2eae8;
  border-top: 20px solid #b7a4a1;
  bottom: 0;
  margin-top: 0;
  position: absolute;
  width: 100%;
}
.news_wrapper__yKHm8 {
  background-color: #fff;
  box-sizing: border-box;
  margin-top: 1830px;
}

/**/

iframe {
  pointer-events: none;
}

.agiliveMobileNewsContainer {
  border-color: #fff;
}

.logo_bottom_news {
  background-color: #f8f9fa;
  height: 120px;
  width: 72px;
  position: absolute;
}
.logo_bottom_news {
  background-color: #f8f9fa;
  height: 120px;
  width: 72px;
  background: #f1eae8;
}
.logo_bottom_news img {
  display: block;
}
.breaking-alert span {
  padding-left: 110px;
  padding-top: 0;
  line-height: 48px;
  height: 48px;
  display: block;
  padding-right: 0px;
  padding-bottom: 0px;
}

::selection {
  color: white;
  background: #b7a4a1;
}

::-moz-selection {
  color: white;
  background: #b7a4a1;
}

code {
  background-color: #004fff;
}

/**/

.loader-container {
  top: 0px;
  left: 0px;
  position: fixed;
  text-align: center;
  background-color: #000;
  width: 100%;
  height: 100%;
  z-index: 1051;
  opacity: 0.5;
}
.loader-container .spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
}
.loader-container .spinner .path {
  stroke: #93bfec;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
/**/

/* home */
/* meteo */
.weather_futureForecastTodayDay__kAbaR {
  border-radius: 0px;
  background: #89726b;
  margin-bottom: 10px;
}
.weather_futureForecastTtodayTemp__Otk\+C {
  padding-top: 5px;
}
.future-forecast .today {
  padding-top: 26px;
  padding-bottom: 26px;
  margin-right: 10px;
}

/* pulsanti */
.metro {
  top: 58%;
}
.item span {
  padding-left: 15px;
  padding-right: 15px;
  bottom: 15px;
}

/* breakibg news */
.logo_bottom_news {
  background: #f1eae8;
}
.bordo-breaking {
  border-bottom: 3px solid #fff;
  height: 26px;
  width: 936px;
  position: absolute;
  left: 112px;
  z-index: 20;
  bottom: 14px;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
}

#root > div:first-child {
  background: url(https://centroleonardopwa.online/imgs/dist/pattern-logo3.png);
  background-repeat: repeat;
  background-size: 200px;
  background-position-y: 100px;
  background-position-x: 0;
}

.metro {
  width: 660px;
}
.item {
  margin: 10px;
}
.i1 {
  background: #c2b5b2de;
}
.i3 {
  background: #947c76de;
}
.i4 {
  background: #a6938ede;
}
.i5 {
  background: #89726bde;
}
.i6 {
  background: #b9aaa6de;
}
.i7 {
  background: #9d8882de;
}
.i9 {
  background: #a6938ede;
  width: 420px;
}
.i8 {
  background: #89726bde;
}

.news_wrapper__yKHm8 {
  border-top: 20px solid #b7a4a1;
  margin-top: 0px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #f8f9fa;
  background-color: #f2eae8;
}
.bordo-breaking {
  bottom: 9px;
  height: 25px;
  left: 113px;
  border-left: 2px solid #210a37;
  width: 935px;
}
.breaking-alert span {
  text-transform: uppercase;
  font-weight: 20px;
}
.logo_bottom_news {
  width: 113px;
}

/* add */

.metro {
  width: 880px;
}
.item {
  width: 200px;
  height: 250px;
}
.i7 {
  width: 273px;
}
.i6 {
  width: 274px;
}
.i4 {
  width: 273px;
}

.i9 {
  /*width: 860px;*/
  width: 640px;
  background: #89726bde;
  height: 300px;
}
.i9 img {
  margin-top: 0;
  padding: 20px;
  max-width: 440px;
}
.i1 {
}
.i3 {
}
.i8 {
  background: #a6938ede;
}
.i5 {
  background: #9d8882de;
}
.item span {
  font-size: 24px;
}
.i7 img {
  height: 230px;
}
.i6 img {
  height: 230px;
}
.i4 img {
  height: 230px;
}

.breaking-alert span {
  font-size: 24px;
  color: #464649 !important;
  text-transform: uppercase;
}
.news_wrapper__yKHm8 {
  background-color: #f8f9fa;
  overflow: hidden;
}
.logo_bottom_news {
  background-color: #f8f9fa;
  width: 72px;
  height: 120px;
}
.logo_bottom_news img {
  display: none;
}
.breaking-alert span {
  height: 60px;
  line-height: 60px;
  padding: 0;
}
.news_breakingAlertWrapper__nDJGp {
  margin-left: -100px;
  width: 1150px;
}

.bordo-breaking {
  width: 950px;
  height: 61px;
  border-right: 12px solid #f8f9fa;
  left: 70px;
  border-left: 2px solid #f8f9fa;
  border-bottom: 13px solid #f8f9fa;
  bottom: 6px;
  border-top: 5px solid #f8f9fa;
  border-color: #f8f9fa;
    border-style: solid;
    border-width: 5px 12px 13px 2px;
    bottom: 6px;
    height: 61px;
    left: 70px;
    width: 950px;
}


#root{
  width: 1080px;
  height: 1920px;
  position: relative;
  background: url(httsps://centroleonardopwa.online/imgs/dist/pattern-logo3.png);
  background-repeat: repeat;
  background-size: 200px;
  background-position-y: 100px;
  background-position-x: 0;

}
.rotate-pioggialeggera{
  width: 1080px;
  height: 1920px;
}

.future-forecast, .future-news {
  position: absolute;
  width: 1080px;
}


.item:focus {
  /* transform: scale(0.9); */
  background-color: #4c4c4c;
}
.item:active {
/*  transform: scale(0.9); */
  background-color: #4c4c4c;
}

.i7 {
    width: 200px;
}
.i6 {
    width: 200px;
}
.i4 {
    width: 200px;
}
.i4 img, .i6 img, .i7 img {
    height: 200px;
}
/*
.i10{
  background-color: #AB9792;
}

*/
/* Home 2 */
.i10{
  background-color: #58438E;
}

/*add 25/01/2023 */
::selection {
  background: #ef8356 !important;
}
::-moz-selection {
  background: #ef8356 !important;
}

#root{
  background-image: none;
  
}
#root > div:first-child  {
background-image: url(https://centroleonardopwa.online/assets/img/pattern-wow.png) !important;
background-position-x: 0;
background-position-y: 100px;
background-repeat: repeat;
background-size: 200px;
}



/* header */
.future-forecast, .future-news {
  background: #554596 !important;
}
.future-forecast .today{
background: #ef8356 !important;
}
.data_top {
background: #ef8356 !important;
font-family: 'scoutregular';
font-size: 24px !important;
padding-top:8px !important;
padding-bottom:8px !important;
}
.ora_top{
font-size:34px !important;
font-family: 'scoutregular';
padding-top:10px;
}
.box_giorno_oggi, .box_giorno{
background: #554596 !important;
font-family: 'scoutlight';
font-size: 22px !important;
}
.temperatura{
font-family: 'scoutlight';
font-size: 20px !important;
}


/* pulsanti */
.i7 {
  background: #ef8356 !important;
  
}
.i6 {
  background: #ef8356 !important;
  
}
.i4 {
  background: #ef8356 !important;
 
}
.i9 {
  background: #ef8356 !important;
}
.i1 {
  background: #ef8356 !important;
}
.i3 {
  background: #ef8356 !important;
}
.i8 {
  background: #ef8356 !important;
}
.i5 {
  background: #ef8356 !important;
}
.item span {
  font-family: 'scoutlight';
  font-size: 30px !important;
}

.logo_bottom_news {
  background-color: #fdf2ee !important;
}
.bordo-breaking {
  border-color: #fdf2ee !important;
}
.breaking-alert span{
  font-family: 'scoutblack';
  font-size: 30px !important;
}

/* add 30/01/2023 */

#root {
  background-image: url(https://centroleonardopwa.online/assets/img/pattern-wow4.png)!important;
  background-position-x: 0;
  background-position-y: 390px;
  background-repeat: repeat;
  background-size: 1080px;
  position: relative;
}
#root>div:first-child {
  background-image: none!important;
}
.future-forecast, .future-news {
  background: #695f58!important;
}
.box_giorno, .box_giorno_oggi {
  background: #695f58!important;
  font-family: scoutregular;
  color: #FFF;
}


.i12 {
  background-color: #BF0D72;
}
.i9{
width:420px;
height:250px
}
.i5 {
  height: 250px!important;
}
.i9 img {
  max-width: 400px;
}
.metro {
  top: 56%;
}