@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;700&display=swap");


/* add 25/01/2023 */
@font-face {
  font-family: 'scoutbold';
  src: url('https://centroleonardopwa.online/assets/fonts/scout-bold-webfont.eot');
  src: url('https://centroleonardopwa.online/assets/fonts/scout-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-bold-webfont.woff2') format('woff2'),
       url('https://centroleonardopwa.online/assets/fonts/scout-bold-webfont.woff') format('woff'),
       url('https://centroleonardopwa.online/assets/fonts/scout-bold-webfont.ttf') format('truetype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-bold-webfont.svg#scoutbold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'scoutextralight';
  src: url('https://centroleonardopwa.online/assets/fonts/scout-extralight-webfont.eot');
  src: url('https://centroleonardopwa.online/assets/fonts/scout-extralight-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-extralight-webfont.woff2') format('woff2'),
       url('https://centroleonardopwa.online/assets/fonts/scout-extralight-webfont.woff') format('woff'),
       url('https://centroleonardopwa.online/assets/fonts/scout-extralight-webfont.ttf') format('truetype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-extralight-webfont.svg#scoutextralight') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'scoutlight';
  src: url('https://centroleonardopwa.online/assets/fonts/scout-light-webfont.eot');
  src: url('https://centroleonardopwa.online/assets/fonts/scout-light-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-light-webfont.woff2') format('woff2'),
       url('https://centroleonardopwa.online/assets/fonts/scout-light-webfont.woff') format('woff'),
       url('https://centroleonardopwa.online/assets/fonts/scout-light-webfont.ttf') format('truetype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-light-webfont.svg#scoutlight') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'scoutregular';
  src: url('https://centroleonardopwa.online/assets/fonts/scout-regular-webfont.eot');
  src: url('https://centroleonardopwa.online/assets/fonts/scout-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-regular-webfont.woff2') format('woff2'),
       url('https://centroleonardopwa.online/assets/fonts/scout-regular-webfont.woff') format('woff'),
       url('https://centroleonardopwa.online/assets/fonts/scout-regular-webfont.ttf') format('truetype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-regular-webfont.svg#scoutregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'scoutthin';
  src: url('https://centroleonardopwa.online/assets/fonts/scout-thin-webfont.eot');
  src: url('https://centroleonardopwa.online/assets/fonts/scout-thin-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-thin-webfont.woff2') format('woff2'),
       url('https://centroleonardopwa.online/assets/fonts/scout-thin-webfont.woff') format('woff'),
       url('https://centroleonardopwa.online/assets/fonts/scout-thin-webfont.ttf') format('truetype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-thin-webfont.svg#scoutthin') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'scoutblack';
  src: url('https://centroleonardopwa.online/assets/fonts/scout-black-webfont.eot');
  src: url('https://centroleonardopwa.online/assets/fonts/scout-black-webfont.eot?#iefix') format('embedded-opentype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-black-webfont.woff2') format('woff2'),
       url('https://centroleonardopwa.online/assets/fonts/scout-black-webfont.woff') format('woff'),
       url('https://centroleonardopwa.online/assets/fonts/scout-black-webfont.ttf') format('truetype'),
       url('https://centroleonardopwa.online/assets/fonts/scout-black-webfont.svg#scoutblack') format('svg');
  font-weight: normal;
  font-style: normal;
}

.wrapper {
  background-color: #fff;
  margin-top: 1830px;
  box-sizing: border-box;
  border-top: 20px solid #b7a4a1;
  background-color: #f8f9fa;
  overflow: hidden;
  bottom: 0;
  margin-top: 0;
  position: absolute;
  width: 100%;
  
  background-color: #f8f9fa;
  overflow: hidden;
  border-top: 20px solid #b7a4a1;
  bottom: 0;
  margin-top: 0;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  margin-top: 1830px;
 
}

.breakingAlertWrapper {
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 0 30px;
  margin-left: -100px;
  width: 1150px;
}

.wrapper .headline-wrapper {
  display: flex;
  height: 110px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px -5px 20px rgb(0 0 0 / 20%);
}

.wrapper .headline-wrapper .logo {
  height: inherit;
  box-shadow: 5px 0 10px rgb(0 0 0 / 20%);
  padding: 10px 15px;
  border-bottom: 15px solid #b6a39e;
  z-index: 2;
}

.wrapper .headline-wrapper .news-wrapper.in {
  opacity: 1;
}

.wrapper .headline-wrapper .news-wrapper {
  border-top: 20px solid #b7a4a1;
  /* bottom: 0; */
  margin-top: 0;
  position: absolute;
  width: 100%;
}

.wrapper .headline-wrapper .news-wrapper .title {
  font-size: 20px;
  line-height: 1;
  font-weight: 600;
  margin: 0;
  padding: 15px 0 0 0;
}

.wrapper .headline-wrapper .news-wrapper .rotator {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 0 25px 0;
  font-size: 22px;
  line-height: 1;
  white-space: nowrap;
  font-weight: 300;
}

.wrapper .headline-wrapper .news-wrapper .rotator:before {
  content: "";
  position: absolute;
  left: -30px;
  top: 0;
  bottom: 0;
  display: block;
  width: 110px;
  background-image: linear-gradient(to right, #fff, transparent);
  z-index: 1;
}

.wrapper .headline-wrapper .news-wrapper .rotator .text-wrapper {
  margin: 0 auto;
  padding: 7px 0;
  white-space: nowrap;
  overflow: hidden;
}

.wrapper .headline-wrapper .news-wrapper .rotator .text-wrapper span {
  display: inline-block;
  margin: 0;
  padding-left: 100%;
  padding-right: 40px;
  animation: marquee 10s linear infinite;
}

.wrapper
  .headline-wrapper
  .news-wrapper
  .rotator
  .text-wrapper
  span:nth-child(2) {
  animation-delay: 5s;
}

.wrapper .headline-wrapper .news-wrapper .rotator .text-wrapper span {
  display: inline-block;
  margin: 0;
  padding-left: 100%;
  padding-right: 40px;
  animation: marquee 10s linear infinite;
}

/* add 25/01/2023 */

/* footer */
.wrapper {
  background-color: #fdf2ee !important;
  border-top: 20px solid #ef8356 !important;
}
.logo_bottom_news {
  background-color: #fdf2ee !important;
}
.bordo-breaking {
  border-color: #fdf2ee !important;
}
.breaking-alert span{
  font-family: 'scoutblack';
  font-size: 30px !important;
}